<template>
  <v-container>
    <v-card color="grey lighten-5" elevation="0">
        <v-row>
          <v-col cols="80" md="2" v-if="condicionVerVentas">
            <v-card
              class="mx-auto text-center"
              color="green"
              dark
              max-width="600"
            >
              <v-card-text  >
                <v-sheet color="rgba(0, 0, 0, .12)">
                  <v-sparkline
                    :value="value"
                    color="rgba(255, 255, 255, .7)"
                    height="80"
                    padding="24"
                    stroke-linecap="round"
                    smooth
                  >
                    <template v-slot:label="item">
                      ${{ item.value }}
                    </template>
                  </v-sparkline>
                </v-sheet>
              </v-card-text>

              <v-card-text>
                <div class="text-h5 font-weight-thin">
                  Ventas
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions class="justify-center">
                <v-btn
                  block
                  text
                  href="/caja"
                >
                  Realizar Ventas
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="80" md="2" v-if="condicionVerCompras">
            <v-card
              class="mx-auto text-center"
              color="cyan"
              dark
              max-width="600"
            >
              <v-card-text>
                <v-sheet color="rgba(0, 0, 0, .12)">
                  <v-sparkline
                    :value="value2"
                    color="rgba(255, 255, 255, .7)"
                    height="80"
                    padding="24"
                    stroke-linecap="round"
                    smooth
                  >
                    <template v-slot:label="item">
                      ${{ item.value }}
                    </template>
                  </v-sparkline>
                </v-sheet>
              </v-card-text>

              <v-card-text>
                <div class="text-h5 font-weight-thin">
                  Compras
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions class="justify-center">
                <v-btn
                  block
                  text
                  href="/facturas"
                >
                  Realizar Compras
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="80" md="2" v-if="condicionVerProductos">
            <v-card
              class="mx-auto text-center"
              color="teal lighten-1"
              dark
              max-width="600"
            >
              <v-card-text>
                <v-sheet color="rgba(0, 0, 0, .12)">
                  <v-sparkline
                    :value="value3"
                    color="rgba(255, 255, 255, .7)"
                    height="80"
                    padding="24"
                    stroke-linecap="round"
                    smooth
                  >
                    <template v-slot:label="item">
                      ${{ item.value }}
                    </template>
                  </v-sparkline>
                </v-sheet>
              </v-card-text>

              <v-card-text>
                <div class="text-h5 font-weight-thin">
                  Productos
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions class="justify-center">
                <v-btn
                  block
                  text
                  href="/producto"
                >
                  Ir a Productos
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="80" md="2" v-if="condicionVerUtilidades">
            <v-card
              class="mx-auto text-center"
              color="blue"
              dark
              max-width="600"
            >
              <v-card-text>
                <v-sheet color="rgba(0, 0, 0, .20)">
                  <v-sparkline
                    :value="value4"
                    color="rgba(255, 255, 255, .7)"
                    height="80"
                    padding="24"
                    stroke-linecap="round"
                    smooth
                  >
                    <template v-slot:label="item">
                      ${{ item.value }}
                    </template>
                  </v-sparkline>
                </v-sheet>
              </v-card-text>

              <v-card-text>
                <div class="text-h5 font-weight-thin">
                  Utilidades
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions class="justify-center">
                <v-btn
                  block
                  text
                >
                  Ver Utilidades
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="80" md="2" v-if="condicionVerKardex">
            <v-card
              class="mx-auto text-center"
              color="blue"
              dark
              max-width="600"
            >
              <v-card-text>
                <v-sheet color="rgba(0, 0, 0, .20)">
                  <v-sparkline
                    :value="value4"
                    color="rgba(255, 255, 255, .7)"
                    height="80"
                    padding="24"
                    stroke-linecap="round"
                    smooth
                  >
                    <template v-slot:label="item">
                      ${{ item.value }}
                    </template>
                  </v-sparkline>
                </v-sheet>
              </v-card-text>

              <v-card-text>
                <div class="text-h5 font-weight-thin">
                  Kardex
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions class="justify-center">
                <v-btn
                  block
                  text
                  href="/kardex"
                >
                  Ver kardex
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    <br>
    <v-card color="grey lighten-5">
      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="basil"
        grow
      >
        <v-tab
          v-for="item in items"
          :key="item"
        >
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="item in items"
          :key="item"
        >
          <v-card
            color="white"
            flat
          >
            <v-card-text v-if="item === 'Productos por vencer'">
              <v-col
                cols="12"
                sm="12"
                xs="12"
                md="3"
              >
                <v-text-field
                  v-model="search"
                  clearable
                  outlined
                  dense
                  clear-icon="mdi-delete"
                  label="Buscar"
                ></v-text-field>
              </v-col>
              <v-data-table
                :headers="expiredHeader"
                :items="expired"
                class="elevation-0"
                :footer-props="{ 'items-per-page-options': [5, 10, 20, 50] }"
                :server-items-length="totalExpired"
                :options.sync="expiredOptions"
              >
                <template v-if="progress" v-slot:no-data>
                  <div class="text-center">
                    <v-progress-circular
                      :size="40"
                      :width="3"
                      color="green"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-text v-else>
              <v-col
                cols="12"
                sm="12"
                xs="12"
                md="3"
              >
                <v-text-field
                  v-model="searchToExpire"
                  clearable
                  outlined
                  dense
                  clear-icon="mdi-delete"
                  label="Buscar"
                ></v-text-field>
              </v-col>
              <v-data-table
                :headers="toExpiredHeader"
                :items="toExpire"
                class="elevation-0"
                :footer-props="{ 'items-per-page-options': [5, 10, 20, 50] }"
                :server-items-length="totalToExpire"
                :options.sync="expireOptions"
              >
                <template v-if="progress" v-slot:no-data>
                  <div class="text-center">
                    <v-progress-circular
                      :size="40"
                      :width="3"
                      color="green"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import debounce from "lodash/debounce";

export default {
  name: "Product",
  data() {
    return {
      value: [
        423,
        446,
        675,
        510,
        590,
        610,
        750,
      ],
      value2: [
        100,
        200,
        100,
        150,
        100,
        175,
        0,
      ],
      value3: [
        100,
        120,
        140,
        120,
        100,
        110,
        150,
      ],
      value4: [
        423,
        446,
        675,
        600,
        590,
        610,
        750,
      ],
      progress: false,
      searchName: '',
      expired: [],
      toExpire: [],
      filters: {
        search1: '',
        search2: ''
      },
      totalExpired: 0,
      totalToExpire: 0,
      expiredOptions: {},

      expireOptions: {},
      expiredHeader: [
        {text: 'PRODUCTO',          value: 'product',      sortable: false, width: "250px", class: "success white--text"},
        {text: 'LABORATORIO',       value: 'laboratory',   sortable: false, width: "150px", class: "success white--text"},
        {text: 'PRESENTACIÓN',      value: 'presentation', sortable: false, width: "50px", class: "success white--text"},
        {text: 'FECHA VENCIMIENTO', value: 'date',         sortable: false, width: "50px", class: "success white--text"},
        {text: 'CANTIDAD',          value: 'stock',        sortable: false, width: "50px", class: "success white--text"},
      ],
      toExpiredHeader: [
        {text: 'PRODUCTO',          value: 'product',      sortable: false, width: "250px", class: "success white--text"},
        {text: 'LABORATORIO',       value: 'laboratory',   sortable: false, width: "150px", class: "success white--text"},
        {text: 'PRESENTACIÓN',      value: 'presentation', sortable: false, width: "50px", class: "success white--text"},
        {text: 'FECHA VENCIMIENTO', value: 'date',         sortable: false, width: "50px", class: "success white--text"},
        {text: 'CANTIDAD',          value: 'stock',        sortable: false, width: "50px", class: "success white--text"},
      ],
      isLoading: false,
      tab: null,
      items: [
        'Productos por vencer', 'Productos vencidos',
      ],
    }
  },
  computed: {
    condicionVerVentas () {
      return this.$store.state.auth.user[0].role_id !== 2;
    },
    condicionVerCompras(){
      return this.$store.state.auth.user[0].role_id === 1;
    },
    condicionVerProductos(){
      return this.$store.state.auth.user[0].role_id === 1;
    },
    condicionVerUtilidades(){
      return this.$store.state.auth.user[0].role_id === 1 || this.$store.state.auth.user[0].role_id === 2;
    },
    condicionVerKardex(){
      return this.$store.state.auth.user[0].role_id !== 2;
    },

    formTitle() {
      return this.editedIndex === -1 ? 'REGISTRAR PRODUCTO' : 'EDITAR PRODUCTO'
    },
    search: {
      get() {
        return this.filters.search1;
      },
      set(value) {
        this.filters.search1 = value;
        this.fetchExpired()
      }
    },
    searchToExpire: {
      get() {
        return this.filters.search2;
      },
      set(value) {
        this.filters.search2 = value;
        this.fetchToExpire()
      }
    }
  },
  watch: {
    expiredOptions: {
      async handler() {
        await this.fetchExpired();
      }
    },
    expireOptions: {
      async handler() {
        await this.fetchToExpire();
      }
    },
  },
  created() {
    this.fetchExpired()
    this.fetchToExpire()
  },
  methods: {
    fetchExpired() {
      const params = {...this.expiredOptions, ...this.filters}
      this.progress = true
      axios.get('/api/products/expired', {params})
        .then(response => {
          this.expired = response.data.data.data
          this.totalExpired = response.data.data.total
          this.progress = false
        })
        .catch(error => console.log(error))
    },
    fetchToExpire() {
      const params = {...this.expireOptions, ...this.filters}
      this.progress = true
      axios.get('/api/products/toexpire', {params})
          .then(response => {
            this.toExpire = response.data.data.data
            this.totalToExpire = response.data.data.total
            this.progress = false
          })
          .catch(error => console.log(error))
    },
    filterSearch: debounce(async function () {
      await this.fetchExpired();
    }),
  },

}
</script>
<style>
.inputs-container,
.col-12 {
  padding-top: 0;
  padding-bottom: 0;
}

.theme--light.v-input {
  padding-top: 0;
}

.v-input__slot {
  margin-bottom: 0;
}

.v-progress-circular {
  margin: 1rem;
}
/* Helper classes */
.basil {
  background-color: #FFFBE6 !important;
}
.basil--text {
  color: #356859 !important;
}
</style>
